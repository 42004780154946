import { inject, observable } from 'aurelia-framework';
import { errorify } from 'aurelia-resources';
import { Global } from 'global';
import { getLogger } from 'aurelia-logging';
import { DynamicDataModel } from 'aurelia-deco';
import * as environment from '../../config/environment.json';
import { QrCodeReader } from './../components/qr-code-reader';
import { Link } from 'app';

const log = getLogger('qr');

@inject(Global)
export class Qr {

  public routeNext: string = 'qrcode';
  public routeParams: any = { na: 'qr' };
  public urlId: string = undefined;
  public link = {} as Link;
  public nbScan: number = 0;

  public showQrReader: boolean = false;
  @observable public qrData: string;
  private qrCodeReader: QrCodeReader;

  constructor(private global: Global) {
    console.log('Start constructor', this.urlId, this.link);
  }

  public activate(params: { urlId: string }) {
    if (params) {
      this.urlId = params.urlId;
      this.getLinks(this.urlId);
    }
  }

  public async getLinks(urlId: string) {
    if (urlId && urlId != '') {
      let links: Link[] = [];
      links = await DynamicDataModel.use('link').getAll(`?urlId=${urlId}`) as Link[];
      if (links && links.length > 0) {
        let link: Link = links[0];
        console.log('link', link);
        this.link.id = link.id;
        this.link.modelId = link.modelId;
        this.link.name = link.name ? link.name : '';
        this.link.buildingName = link.buildingName ? link.buildingName : '';
        this.link.roomNb = link.roomNb ? link.roomNb : '';
        this.link.urlId = link.urlId ? link.urlId : '';
        this.link.urlTarget = link.urlTarget ? link.urlTarget : '';
        if (this.link.urlTarget && this.link.urlTarget != '') {
          this.redirect(false);
        }
      }
    }
  }

  public async redirect(force: boolean) {
    if (force) {
      window.open(this.link.urlTarget, "_self");
    } else {
      setTimeout(() => {
        window.open(this.link.urlTarget, "_self");
        // this.global.navigateToRoute('qr',{urlId:'kjhbjkhb'}); // Go to page with parameter
      }, 5000);
    }
  }



  public startScan() {
    if (environment.qrCodeBase) {
      this.toggleShowQrReader();
    }
    else {
      errorify(new Error("QR code base settings is empty"));
    }
  }
  public toggleShowQrReader() {
    this.showQrReader = !this.showQrReader
    if (this.showQrReader) {
      this.qrCodeReader.startQrReader();
    } else {
      this.qrCodeReader.StopQrReader();
    }
  }
  
  private qrDataChanged(newValue: string) {
    if (environment.qrCodeBase && newValue?.toLocaleLowerCase().includes(environment.qrCodeBase.toLocaleLowerCase())) {
      let qrCode = newValue.replace(environment.qrCodeBase, '');
      this.toggleShowQrReader();
       this.getLinks(qrCode);
      this.nbScan += 1;
      // setTimeout(() => {
      //   this.toggleShowQrReader(); // TEST ONLY
      // }, 500);
    }
  }

}
